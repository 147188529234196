import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  addTicketAttachment,
  uploaLoadTicketImage,
} from "../../helpers/services/api.services";
import {
  fetchTicketCategoies,
  fetchTicketSubCategoies,
  submitTicket,
  submitTicketCommonArea,
} from "../../helpers/services/admin.services";

const NewTicketModal = ({
  showModal,
  setShowModal,
  residentData,
  propertyDetails,
}) => {
  const [data, setData] = useState({
    email: "",
    subCategory: "",
    description: "",
  });

  const [categoriesData, setCategoriesData] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [imageToken, setImageToken] = useState("");
  const [imageUploadLoading, setImageUplaodLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const fetchCategoriesData = async () => {
    try {
      const response = await fetchTicketCategoies();
      setCategoriesData(response?.data?.result);
    } catch (error) {
      setCategoriesData([]);
    }
  };
  const fetchSubCategoriesData = async () => {
    try {
      const response = await fetchTicketSubCategoies();
      setSubCategoriesData(response?.data?.result);
    } catch (error) {
      setSubCategoriesData([]);
    }
  };

  useEffect(() => {
    fetchCategoriesData();
    fetchSubCategoriesData();
  }, []);

  const handleCategoryChange = (e) => {
    const { value } = e.target;

    const filteredSubCategories = subCategoriesData.filter((subCategory) =>
      subCategory.parentOptionId.includes(parseInt(value))
    );

    setFilteredSubCategories(filteredSubCategories);

    setData((prevData) => ({
      ...prevData,
      category: value, 
      subCategory: "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    try {
      setImageUplaodLoading(true);

      const formData = new FormData();
      formData.append("file_path", e.target.files[0]);

      const response = await addTicketAttachment(formData);
      if (response.status == "success") {
        setImageToken(response.data.token);
      } else {
        toast.error("Image not uploaded");
      }
    } catch (error) {
      toast.error("Failed to upload image. Please try again later.");
    } finally {
      setImageUplaodLoading(false);
    }
  };

  const clearData = () => {
    setData({
      category: "",
      subCategory: "",
      description: "",
    });
    setImageToken("");
    setSelectedCategory("");
    setFilteredSubCategories([]);
  };

  const handleSubmit = async () => {
    const { category, subCategory, description } = data;

    if (!category.trim() || !subCategory.trim() || !description.trim()) {
      return toast.error("Please fill all fields!");
    }

    if(propertyDetails && !selectedProperty ){
      return toast.error("Please fill all fields!");
    }

    const submit_data = {
      category_id: data.category,
      subject: selectedCategory,
      sub_category_id: data.subCategory,
      description,
      attachment_token: imageToken,
    };

    if(selectedProperty && propertyDetails){
      submit_data.property_id = selectedProperty
    }

    if (residentData) {
      submit_data.lead_generation_id = residentData.ys_id
        ? residentData.ys_id
        : residentData.basic_details?.lead_generation_id;
    }

    try {
      setSubmitLoading(true);


      let response = null
      
      if(propertyDetails){
        response = await submitTicketCommonArea(submit_data);
      }

      if(residentData){
        response = await submitTicket(submit_data);
      }


      if (response.status == "success") {
        toast.success("Ticket submitted successfully!");
        setShowModal(false);
        clearData();
      }
    } catch (error) {
      toast.error("Failed to submit ticket. Please try again later.");
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        clearData();
      }}
      centered
      size="lg"
      className="commen_modal"
    >
      <Modal.Header>
        <Modal.Title>
          <b>New Ticket</b>
        </Modal.Title>
        <img
          src="/images/cross_modal.svg"
          className="cursor-pointer"
          alt="Close Icon"
          onClick={() => {
            setShowModal(false);
            clearData();
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="form_control_box">
          <label htmlFor="category" className="form-label">
            <b>Category</b>
          </label>
          <select
            id="category"
            className="form-control mt-1"
            name="category"
            onChange={(e) => {
              handleCategoryChange(e);

              // Get the selected category name
              const selectedCategory = categoriesData.find(
                (cat) => cat.id === parseInt(e.target.value)
              )?.name;

              setSelectedCategory(selectedCategory || "");
            }}
            value={data.category}
          >
            <option value="">Select a category</option>
            {categoriesData.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form_control_box">
          <label htmlFor="subCategory" className="form-label">
            <b>Sub-Category</b>
          </label>
          <select
            id="subCategory"
            className="form-control mt-1"
            name="subCategory"
            onChange={handleChange}
            value={data.subCategory}
            disabled={!data.category}
          >
            <option value="">Select a sub-category</option>
            {filteredSubCategories.map((subCat) => (
              <option key={subCat.id} value={subCat.id}>
                {subCat.name}
              </option>
            ))}
          </select>
        </div>
        {propertyDetails && (
          <div className="form_control_box">
            <label htmlFor="category" className="form-label">
              <b>Property</b>
            </label>
            <select
              name=""
              id=""
              className="form-control mt-1"
              onChange={(e) => setSelectedProperty(e.target.value)}

            >
              Select Property
              <option value="">Select Property</option>
              {propertyDetails &&
                propertyDetails.map((cluster, index) => (
                  <option key={index} value={cluster.value}>
                    {cluster.label}
                  </option>
                ))}
            </select>
          </div>
        )}
        <div className="form_control_box">
          <label htmlFor="description" className="form-label">
            <b>Description</b>
          </label>
          <textarea
            id="description"
            className="form-control mt-1"
            placeholder=" Enter ticket description "
            name="description"
            onChange={handleChange}
            value={data.description}
          />
        </div>
        <div className="form_control_box">
          <label htmlFor="image" className="form-label">
            <b>Upload Image (optional) </b>
          </label>
          <input
            type="file"
            id="image"
            className="form-control mt-1"
            name="image"
            onChange={handleFileChange}
            disabled={imageUploadLoading}
            accept="image/*"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="orange_brd_button"
          onClick={() => {
            setShowModal(false);
            clearData();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="basic-button"
          onClick={handleSubmit}
          disabled={submitLoading || imageUploadLoading}
        >
          {submitLoading ? "Submitting..." : "Submit"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewTicketModal;
